define([
    'santa-components',
    'lodash',
    'prop-types',
    'textCommon',
    'skins',
    'comboBoxInput/skins/skins.json',
    'componentsCore'
], function (
    santaComponents,
    _,
    PropTypes,
    textCommon,
    skinsPackage,
    skinsJson,
    componentsCore
) {
    'use strict';

    const labelUtils = textCommon.labelUtils;

    function getOption(opt) {
        return santaComponents.utils.createReactElement(
            'option',
            {value: opt.value, default: opt.default, style: opt.style, ref: opt.ref || opt.value, disabled: opt.disabled, key: opt.key},
            opt.text
        );
    }

    function getComboBoxChildren(options, placeholder, defaultValue, placeholderClass) {
        let foundDefault = false;
        const comboBoxOptions = _.map(options, function (opt, i) {
            foundDefault = foundDefault || opt.value === defaultValue;
            return getOption(_.assign({default: opt.value === defaultValue, key: i}, opt));
        });

        let placeholderOption;
        if (placeholder) {
            placeholderOption = _.assign(
                {disabled: true, default: !foundDefault, style: {display: 'none'}, className: placeholderClass, ref: 'placeholder', key: `placeholder-${placeholder.value}`},
                placeholder,
                {value: ''});
        } else {
            placeholderOption = {default: !foundDefault, style: {display: 'none'}, className: placeholderClass, text: '', value: '', ref: 'placeholder', key: 'noPlaceholder'};
        }
        comboBoxOptions.unshift(getOption(placeholderOption));

        return comboBoxOptions;
    }

    function getPlaceholderValue(props) {
        return _.get(props, ['compProp', 'placeholder', 'value']);
    }

    function isLegacyPlaceholder(props) {
        return props.compData.value === getPlaceholderValue(props);
    }

    function getComboBoxValue(props) {
        return isLegacyPlaceholder(props) ? '' : props.compData.value;
    }

    function isLegacyPlaceholderSelected(state, placeholderValue) {
        return state.value === placeholderValue;
    }

    function isPlaceholderSelected(state, placeholderValue) {
        return placeholderValue && state.value === '';
    }

    const getPublicState = function (state) {
        return santaComponents.mixins.validatableMixin.getPublicState(state);
    };

    function getCssState(props) {
        return {
            '$validity': props.errorMessage ? 'invalid' : 'valid'
        };
    }

    /**
     * @class components.ComboBoxInput
     * @extends {core.skinBasedComp}
     */
    const comboBoxInput = {
        displayName: 'ComboBoxInput',
        mixins: [
            componentsCore.mixins.skinBasedComp,
            santaComponents.mixins.runTimeCompData,
            textCommon.textScaleMixin,
            santaComponents.mixins.inputFocusMixin,
            santaComponents.mixins.validatableMixin.validatable,
            santaComponents.mixins.compStateMixin(getPublicState)
        ],

        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
            onSelectionChange: PropTypes.func,
            shouldResetComponent: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetComponent,
            errorMessage: PropTypes.bool,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView
        },
        statics: {
            behaviors: _.assign({},
                santaComponents.mixins.inputFocusMixin.INPUT_FOCUS_BEHAVIORS,
                santaComponents.mixins.validatableMixin.VALIDATABLE_BEHAVIORS
            )
        },
        focus() {
            this.refs.collection.focus();
        },
        blur() {
            this.refs.collection.blur();
        },

        getInitialState() {
            this.shouldResetComponent = this.props.shouldResetComponent;
            return _.assign(getCssState(this.props), getPublicState(), {
                value: getComboBoxValue(this.props),
                defaultValue: this.props.compData.value,
                placeholderValue: getPlaceholderValue(this.props)
            });
        },

        setCustomValidity(customValidity) {
            this.refs.collection.setCustomValidity(customValidity);
        },

        componentWillReceiveProps(nextProps) {
            if (nextProps.shouldResetComponent && nextProps.shouldResetComponent !== this.shouldResetComponent) {
                this.hideValidityIndication();
            }

            const newState = getCssState(nextProps);

            const isValueChanged = nextProps.compData.value !== this.state.value;
            const isPlaceholderChanged = getPlaceholderValue(nextProps) !== this.state.placeholderValue;

            if (isValueChanged || isPlaceholderChanged || this.props.onSelectionChange) {
                newState.value = getComboBoxValue(nextProps);
            }
            this.shouldResetComponent = nextProps.shouldResetComponent;
            this.setState(newState);
        },

        onChange(event, domID) {
            const value = event.target.value;
            this.updateData({value});
            if (this.props.onSelectionChange) {
                event.type = 'selectionChanged';
                event.payload = _.find(this.props.compData.options, {value}) || {};
                this.props.onSelectionChange(event, domID);
            } else {
                this.setState({value: value ? value : ''});
            }
            this.handleAction('change', event);
        },

        onFocus(event) {
            this.handleAction('focus', event);
        },

        onBlur(event) {
            this.handleAction('blur', event);
        },

        getPlaceholderClass() {
            return this.isPlaceholderSelected() ? this.classSet({'extended-placeholder-style': true}) : '';
        },

        getCollectionClassName() {
            const propClass = {};
            if (this.props.compProp.collectionClass) {
                propClass[this.props.compProp.collectionClass] = true;
            }

            return [
                this.getPlaceholderClass(),
                'has-custom-focus',
                this.classSet(propClass)
            ].join(' ');
        },

        getSkinProperties() {
            const {compProp, isMobileView, compData, compTheme} = this.props;
            const selectedClassSet = {
                'with-validation-indication': this.shouldShowValidityIndication(),
                'required': labelUtils.showRequired(compData, compProp, compTheme)
            };
            selectedClassSet[`${this.props.compProp.textAlignment}-direction`] = true;
            if (this.props.compProp.class) {
                selectedClassSet[this.props.compProp.class] = true;
            }
            const paddingObj = {};
            paddingObj[`padding${_.capitalize(this.props.compProp.textAlignment)}`] = this.props.compProp.textPadding;
            const options = this.props.compData.options;
            const id = {compId: this.props.id, inputId: 'collection'};

            return _.merge(labelUtils.getBaseInputWithLabelSkinProperties({compProp, rawFontSyle: this.getFontSize('fntlbl'), isMobileView, compData, id}), {
                '': {
                    className: this.classSet(selectedClassSet),
                    'data-disabled': !!this.props.compProp.isDisabled,
                    'data-preview': _.isFunction(this.getComponentPreviewState) && this.getComponentPreviewState()
                },
                collection: {
                    disabled: !!_.get(this.props, 'compProp.isDisabled'),
                    children: getComboBoxChildren(options, this.props.compData.placeholder || this.props.compProp.placeholder, this.state.defaultValue, this.classSet({'placeholder-style': true})),
                    value: this.state.value,
                    required: this.props.compProp.required,
                    onChange: this.onChange,
                    onFocus: this.onFocus,
                    onBlur: this.onBlur,
                    style: _.merge(paddingObj, this.getFontSize()),
                    className: this.getCollectionClassName(),
                    'data-preview': _.isFunction(this.getComponentPreviewState) && this.getComponentPreviewState()
                },
                errorMessage: {
                    children: [this.props.errorMessage]
                }
            });
        },

        measureComponent(node, $) {
            const {icon, collection, label} = this.refs;
            const iconHeightWithPadding = icon ? icon.getBBox().height + 16 : 0;
            const selectLineHeight = parseInt($(collection).css('line-height'), 10) || 0;
            const borderWidth = parseInt($(collection).css('border-width'), 10) || 0;
            const totalBorderSize = borderWidth * 2;
            const minHeight = Math.max(selectLineHeight, iconHeightWithPadding) + totalBorderSize + _.get(label, 'offsetHeight', 0);

            const heightWithlabel = label ? labelUtils.measureComponentWithLabel(this.props.compProp, label, this.props.compData, this.props.isMobileView) : 0;
            const height = heightWithlabel ? Math.max(heightWithlabel, minHeight) : Math.max(collection.offsetHeight, minHeight);
            return [{node, type: 'css', changes: {width: Math.max(node.offsetWidth, collection.offsetWidth), minHeight, height}}];
        },

        isPlaceholderSelected() {
            const placeholderValue =
                _.get(this.props.compData, 'placeholder.value') ||
                _.get(this.props.compProp, 'placeholder.value');

            return isLegacyPlaceholderSelected(this.state, placeholderValue) ||
                isPlaceholderSelected(this.state, placeholderValue);
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.inputs.ComboBoxInput', comboBoxInput);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return comboBoxInput;
});
